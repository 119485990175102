.moduleWrapper {
    padding: 3.68vw 7.35vw;
}

.moduleWrapper .textWrapper {
    font-family: "Akzidenz Light";
    font-size: 2.36vw;
}

@media (max-width: 820px) {
    .moduleWrapper {
        padding: 7vw 4.032vw;
    }
    .moduleWrapper .textWrapper {
        font-size: 3.06vw;
    }
}

@media (max-width: 430px) {
    .moduleWrapper {
        padding: 7vw 4.933vw;
    }
    .moduleWrapper .textWrapper {
        font-size: 5.3vw;
        line-height: 5.8vw;
    }
}
