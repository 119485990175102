.moduleWrapper {
    min-height: 100vh;
}

.moduleWrapper .header {
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
}

.moduleWrapper .header .headline {
    width: 72%;
    position: relative;
    bottom: -25%;
}

.moduleWrapper .header .headline h1 {
    font-family: "Akzidenz Light";
    font-size: 3.68vw;
    line-height: 3.68vw;
}

.moduleWrapper .header .imgWrapper {
    width: 72%;
    aspect-ratio: 16/9;
    background-position: center;
    background-size: cover;
    position: relative;
    bottom: -37%;
}

.moduleWrapper .valuesModuleWrapper {
    min-height: 140vh;
    background-color: #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.moduleWrapper .mobileParallaxWrapper {
    display: none;
}

.moduleWrapper .mobileParallaxWrapper .headlineWrapper {
    height: 100lvh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.moduleWrapper .mobileParallaxWrapper .headlineWrapper .parallaxHeadline {
    font-family: "Akzidenz";
    font-size: 3.68vw;
    line-height: 3.68vw;
    color: #fff;
    width: 72%;
}

.moduleWrapper .parallaxWrapper {
    background-color: #000;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.moduleWrapper .parallaxWrapper .parallaxOverlay {
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.27;
}

.moduleWrapper .parallaxWrapper .headlineWrapper {
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.moduleWrapper .parallaxWrapper .headlineWrapper .parallaxHeadline {
    font-family: "Akzidenz";
    font-size: 3.68vw;
    line-height: 3.68vw;
    color: #fff;
    width: 72%;
}

.moduleWrapper .ceoModule {
    min-height: 90vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 0.52vw;
    background-color: #fff;
}

.moduleWrapper .ceoModule .leftCol {
    width: 35vw;
    padding-top: 12%;
    padding-bottom: 5%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    row-gap: 3.15vw;
}

.moduleWrapper .ceoModule .leftCol .nameWrapper {
    font-size: 1.84vw;
    line-height: 2.36vw;
}

.moduleWrapper .ceoModule .leftCol .nameWrapper .ceoName {
    font-family: "Akzidenz Medium";
}

.moduleWrapper .ceoModule .leftCol .nameWrapper .ceoPosition {
    font-family: "Akzidenz Light Italic";
}

.moduleWrapper .ceoModule .leftCol .ceoBio {
    font-family: "Akzidenz Light";
    font-size: 2.36vw;
    line-height: 2.63vw;
}

.moduleWrapper .ceoModule .leftCol .contactWrapper {
    font-family: "Akzidenz";
    font-size: 1.31vw;
    line-height: 1.84vw;
    padding-top: 2.63vw;
}

.moduleWrapper .ceoModule .rightCol {
    width: 35vw;
    overflow: visible;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.moduleWrapper .ceoModule .rightCol .ceoImgWrapper {
    height: 85%;
    width: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.moduleWrapper .teamMembers {
    min-height: 100vh;
    background-color: #eeede3;
    padding: 11.82vw 7.35vw;
}

.moduleWrapper .teamMembers .teamTextWrapper {
    font-family: "Untitled";
    font-size: 1.84vw;
    line-height: 2.36vw;
    width: 63%;
    padding-bottom: 4.2vw;
}

.moduleWrapper .teamMembers .membersWrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr) [4];
    grid-template-columns: repeat(4, 1fr);
    gap: 0.79vw;
}

@media (max-width: 820px) {
    .moduleWrapper .header {
        height: 60svh;
    }
    .moduleWrapper .header .imgWrapper {
        bottom: -40%;
    }
    .moduleWrapper .valuesModuleWrapper {
        height: 120vh;
    }
    .moduleWrapper .parallaxWrapper {
        display: none;
    }
    .moduleWrapper .parallaxWrapper .headlineWrapper .parallaxHeadline {
        font-size: 6.173vw;
        line-height: 6.173vw;
        width: 85%;
    }
    .moduleWrapper .mobileParallaxWrapper {
        display: block;
        height: 100svh;
        width: 100%;
        position: relative;
    }
    .moduleWrapper .mobileParallaxWrapper .paraImage {
        height: 100svh;
        width: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: fixed;
        top: 0;
        z-index: -1;
    }
    .moduleWrapper .mobileParallaxWrapper .headlineWrapper .parallaxHeadline {
        font-size: 6.173vw;
        line-height: 6.173vw;
        width: 85%;
    }
    .moduleWrapper .ceoModule {
        height: 50vh;
    }
    .moduleWrapper .ceoModule .leftCol {
        width: 70%;
        padding-left: 3.704vw;
    }
    .moduleWrapper .ceoModule .leftCol .nameWrapper {
        font-size: 2.8vw;
        line-height: 3.2vw;
    }
    .moduleWrapper .ceoModule .leftCol .contactWrapper {
        font-size: 1.8vw;
        line-height: 2.2vw;
    }
    .moduleWrapper .ceoModule .rightCol {
        width: 70%;
    }
    .moduleWrapper .ceoModule .rightCol .ceoImgWrapper {
        height: 80%;
    }
    .moduleWrapper .teamMembers {
        min-height: 60vh;
        padding: 10vw 3.704vw;
    }
    .moduleWrapper .teamMembers .teamTextWrapper {
        font-size: 3.704vw;
        line-height: 4vw;
        width: 80%;
    }
    .moduleWrapper .teamMembers .membersWrapper {
        -ms-grid-columns: (1fr) [2];
        grid-template-columns: repeat(2, 1fr);
        gap: 1.8vw;
    }
}

@media (max-width: 430px) {
    .moduleWrapper .header {
        height: 70svh;
    }
    .moduleWrapper .header .headline {
        width: 100%;
        padding: 0 5.305vw;
        bottom: -20%;
    }
    .moduleWrapper .header .headline h1 {
        font-size: 7vw;
        line-height: 7.5vw;
    }
    .moduleWrapper .header .imgWrapper {
        width: 90%;
        margin: 0 5.305vw;
        bottom: -37%;
    }
    .moduleWrapper .valuesModuleWrapper {
        height: 130vh;
    }
    .moduleWrapper .parallaxWrapper .headlineWrapper .parallaxHeadline {
        font-size: 6.173vw;
        line-height: 6.173vw;
        width: 85%;
    }
    .moduleWrapper .ceoModule {
        height: 90vh;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        gap: 3vw;
    }
    .moduleWrapper .ceoModule .leftCol {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        width: 90%;
        padding-left: 3.704vw;
        padding-top: unset;
    }
    .moduleWrapper .ceoModule .leftCol .nameWrapper {
        font-size: 4.8vw;
        line-height: 5.2vw;
    }
    .moduleWrapper .ceoModule .leftCol .ceoBio {
        font-size: 5vw;
        line-height: 5vw;
    }
    .moduleWrapper .ceoModule .leftCol .contactWrapper {
        font-size: 3.5vw;
        line-height: 4vw;
    }
    .moduleWrapper .ceoModule .rightCol {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        width: 100%;
        height: 50vh;
    }
    .moduleWrapper .ceoModule .rightCol .ceoImgWrapper {
        height: 100%;
    }
    .moduleWrapper .teamMembers {
        min-height: 60vh;
        padding: 10vw 3.704vw;
    }
    .moduleWrapper .teamMembers .teamTextWrapper {
        font-size: 4.831vw;
        line-height: 4.831vw;
        width: 89%;
    }
    .moduleWrapper .teamMembers .membersWrapper {
        -ms-grid-columns: (1fr) [1];
        grid-template-columns: repeat(1, 1fr);
        gap: 6vw;
    }
}
