.moduleWrapper {
    background-color: #eeede3;
    position: relative;
    z-index: 1;
}

.moduleWrapper .header {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.moduleWrapper .header .playerWrapper {
    width: 100%;
    height: 100%;
}

.moduleWrapper .header .playerWrapper .player {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 0.8;
}

.moduleWrapper .header .scroll {
    position: absolute;
    bottom: 3.41vw;
    left: 2.63vw;
    font-family: "Untitled";
    font-size: 0.68vw;
    line-height: 0.84vw;
    z-index: 1000;
}

.moduleWrapper .header .scroll::before {
    background-image: url(../../assets/images/svg/downArrow.svg);
    height: 0.6vw;
    aspect-ratio: 1/1;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    content: " ";
    margin-right: 0.8vw;
}

.moduleWrapper .ourProjects {
    padding-top: 4.26vw;
    overflow: hidden;
    min-height: 100vh;
}

.moduleWrapper .ourProjects .title {
    padding-bottom: 3.52vw;
}

.moduleWrapper .ourProjects .featuredWrapper {
    max-width: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    padding: 0 7.35vw;
    -webkit-column-gap: 0.79vw;
    column-gap: 0.79vw;
}

.moduleWrapper .ourProjects .projectLabel {
    cursor: pointer;
}

.moduleWrapper .ourProjects .projectsGrid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr) [3];
    grid-template-columns: repeat(3, 1fr);
    -webkit-column-gap: 0.79vw;
    column-gap: 0.79vw;
    row-gap: 5.25vw;
    padding: 7.35vw;
}

.moduleWrapper .ourApproach {
    height: 100vh;
}

.moduleWrapper .ourApproach .textWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    height: 100%;
}

.moduleWrapper .ourApproach .textWrapper .buttonWrapper {
    width: 100%;
    padding-left: 14.323vw;
}

.moduleWrapper .ourApproach .textWrapper .approachText {
    width: 87.5vw;
    font-family: "Akzidenz Light";
    font-size: 2.344vw;
    line-height: 2.604vw;
    padding-left: 14.323vw;
}

.moduleWrapper .ourApproach .textWrapper .approachText::before {
    content: " ";
    background-image: url(../../assets/images/svg/livwrk.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 12.34vw;
    aspect-ratio: 225/51;
    margin-right: 0.8vw;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.moduleWrapper .ecosystem .ecosystemContent {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr) [12];
    grid-template-columns: repeat(12, 1fr);
    -webkit-column-gap: 0.84vw;
    column-gap: 0.84vw;
    padding: 7.88vw 0 7.88vw 7.35vw;
}

.moduleWrapper .ecosystem .ecosystemContent .ecosystemDesc {
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
    grid-column: 1/5;
    font-family: "Akzidenz";
    font-size: 1.84vw;
    padding-right: 5vw;
    border-right: 1px solid rgba(112, 112, 112, 0.27);
}

.moduleWrapper .ecosystem .ecosystemContent .articles {
    -ms-grid-column: 6;
    -ms-grid-column-span: 7;
    grid-column: 6/13;
    overflow: hidden;
}

@media (max-width: 1366px) {
    .moduleWrapper .header .scroll {
        left: 1.74vw;
        bottom: 5vw;
    }
    .moduleWrapper .ourProjects .keenSlider {
        padding-left: 10.37vw;
    }
    .moduleWrapper .ourApproach {
        padding-bottom: 10vw;
    }
    .moduleWrapper .ourApproach .textWrapper .buttonWrapper {
        width: 70.12vw;
    }
    .moduleWrapper .ourApproach .textWrapper .approachText {
        width: 70.12vw;
        font-size: 2.59vw;
        line-height: 2.96vw;
    }
    .moduleWrapper .ourApproach .textWrapper .approachText::before {
        width: 13.56vw;
    }
    .moduleWrapper .ecosystem .ecosystemContent {
        padding: 7.88vw 0 7.88vw 10.37vw;
    }
}

@media (max-width: 820px) {
    .moduleWrapper .header .scroll {
        font-size: 1.605vw;
        left: 4.012vw;
        bottom: 9.877vw;
    }
    .moduleWrapper .header .scroll::before {
        height: 1.235vw;
    }
    .moduleWrapper .header .playerWrapper {
        padding-top: unset;
        height: 100%;
        width: calc(100vh / 9 * 16);
        -webkit-transform: translateX(-39%);
        transform: translateX(-39%);
    }
    .moduleWrapper .ourProjects {
        height: -webkit-max-content;
        height: -moz-max-content;
        height: max-content;
        padding-top: 12.346vw;
        padding-bottom: 17.284vw;
    }
    .moduleWrapper .ourProjects .title {
        padding-bottom: 5.741vw;
    }
    .moduleWrapper .ourProjects .featuredWrapper {
        -webkit-column-gap: 1.852vw;
        column-gap: 1.852vw;
        padding: 0 3.704vw;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        row-gap: 5.741vw;
    }
    .moduleWrapper .ourProjects .projectsGrid {
        -ms-grid-columns: (1fr) [2];
        grid-template-columns: repeat(2, 1fr);
        -webkit-column-gap: 1.852vw;
        column-gap: 1.852vw;
        padding: 3.704vw;
    }
    .moduleWrapper .ourApproach {
        height: 60vh;
    }
    .moduleWrapper .ourApproach .textWrapper .buttonWrapper {
        width: 100vw;
        padding-left: 5.072vw;
    }
    .moduleWrapper .ourApproach .textWrapper .approachText {
        width: 100vw;
        font-size: 3.086vw;
        line-height: 3.704vw;
        padding: 0 20vw 0 3.951vw;
    }
    .moduleWrapper .ourApproach .textWrapper .approachText::before {
        width: 14.815vw;
        margin-right: 0.8vw;
    }
    .moduleWrapper .ecosystem .ecosystemContent {
        padding: 7.88vw 3.704vw;
    }
    .moduleWrapper .ecosystem .ecosystemContent .ecosystemDesc {
        -ms-grid-column: 1;
        -ms-grid-column-span: 12;
        grid-column: 1/13;
        font-size: 4.831vw;
        border-right: unset;
    }
    .moduleWrapper .ecosystem .ecosystemContent .articles {
        -ms-grid-column: 1;
        -ms-grid-column-span: 12;
        grid-column: 1/13;
        overflow: hidden;
        padding-top: 6.039vw;
    }
}

@media (max-width: 430px) {
    .moduleWrapper .header {
        height: 100svh;
    }
    .moduleWrapper .header .scroll {
        font-size: 3.467vw;
        left: 5.333vw;
        bottom: 10.667vw;
    }
    .moduleWrapper .header .scroll::before {
        height: 2.667vw;
    }
    .moduleWrapper .ourApproach {
        height: 50vh;
    }
    .moduleWrapper .ourApproach .textWrapper .buttonWrapper {
        width: 100vw;
        padding-left: 5.072vw;
    }
    .moduleWrapper .ourApproach .textWrapper .approachText {
        width: 100vw;
        font-size: 4.831vw;
        line-height: 4.831vw;
        padding: 0 5.072vw 0 5.072vw;
    }
    .moduleWrapper .ourApproach .textWrapper .approachText::before {
        width: 21.256vw;
        margin-right: 0.8vw;
    }
    .moduleWrapper .ourProjects .projectsGrid {
        -ms-grid-columns: (1fr) [1];
        grid-template-columns: repeat(1, 1fr);
        -webkit-column-gap: 0;
        column-gap: 0;
    }
    .moduleWrapper .ecosystem .ecosystemContent {
        padding: 7.88vw 5.072vw 7.88vw 5.072vw;
    }
    .moduleWrapper .ecosystem .ecosystemContent .ecosystemDesc {
        -ms-grid-column: 1;
        -ms-grid-column-span: 12;
        grid-column: 1/13;
        font-size: 4.831vw;
        border-right: unset;
    }
    .moduleWrapper .ecosystem .ecosystemContent .articles {
        -ms-grid-column: 1;
        -ms-grid-column-span: 12;
        grid-column: 1/13;
        overflow: hidden;
        padding-top: 6.039vw;
    }
}
