.moduleWrapper {
    height: 150vh;
    max-width: 100%;
    overflow: hidden;
}

.moduleWrapper .img {
    aspect-ratio: 1920/1455;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

@media (max-width: 820px) {
    .moduleWrapper {
        height: 100vh;
    }
}

@media (max-width: 430px) {
    .moduleWrapper {
        height: 80vh;
    }
}
