.moduleWrapper {
    cursor: -webkit-grab;
    cursor: grab;
}

.moduleWrapper:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.moduleWrapper .imgWrapper {
    width: 100%;
    overflow: hidden;
}

.moduleWrapper .imgWrapper img {
    width: 100%;
    aspect-ratio: 22/13;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.moduleWrapper .title {
    font-family: "Akzidenz";
    font-size: 1.84vw;
    letter-spacing: -0.075vw;
    padding-top: 0.68vw;
    padding-bottom: 0.26vw;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.moduleWrapper .subtitle {
    font-family: "Untitled";
    font-size: 0.89vw;
    opacity: 0.5;
}

.moduleWrapper:hover img {
    scale: 1.05;
}

.moduleWrapper:hover .title {
    opacity: 0.5;
}

@media (max-width: 820px) {
    .moduleWrapper .title {
        font-size: 3.704vw;
        letter-spacing: -0.123vw;
    }
    .moduleWrapper .subtitle {
        font-size: 1.852vw;
    }
}

@media (max-width: 430px) {
    .moduleWrapper .title {
        font-size: 6.039vw;
        letter-spacing: -0.213vw;
        padding-bottom: 0.1vw;
    }
    .moduleWrapper .subtitle {
        font-size: 2.899vw;
    }
}
