.moduleWrapper {
    background-color: #eeede3;
    min-height: 100vh;
}

.moduleWrapper .headerText,
.moduleWrapper .projectText,
.moduleWrapper .marketsText,
.moduleWrapper .partnerText {
    font-family: "Akzidenz Light";
    font-size: 1.84vw;
    line-height: 2.1vw;
    padding: 1.57vw 7.35vw 5.25vw;
}

.moduleWrapper .header {
    height: 150vh;
    padding: 14.5vw 7.35vw;
    overflow: hidden;
}

.moduleWrapper .header .headerText {
    padding: 0 0 5.25vw 0;
}

.moduleWrapper .header .welcomeImage {
    width: 100%;
    aspect-ratio: 1640/1080;
    background-size: cover;
    background-position: center;
}

.moduleWrapper .projectWrapper,
.moduleWrapper .marketsWrapper,
.moduleWrapper .partnerWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-top: 5.25vw;
    overflow: hidden;
}

.moduleWrapper .mapWrapper {
    margin: 2.63vw 7.35vw;
    max-width: 100%;
}

.moduleWrapper .mapWrapper .map {
    aspect-ratio: 1488/783;
    background-size: cover;
}

.moduleWrapper .sliderWrapper {
    margin-left: 7.35vw;
}

.moduleWrapper .sliderWrapper .projectSlider {
    width: 100%;
}

@media (max-width: 1366px) {
    .moduleWrapper .headerText,
    .moduleWrapper .projectText,
    .moduleWrapper .marketsText,
    .moduleWrapper .partnerText {
        font-size: 1.853vw;
        line-height: 2vw;
        padding: 1.57vw 10.378vw 5.25vw 10.378vw;
    }
    .moduleWrapper .header {
        padding: 14.5vw 10.378vw;
    }
    .moduleWrapper .mapWrapper {
        padding: 2.63vw 10.378vw;
    }
    .moduleWrapper .projectSlider {
        margin-left: 10.378vw;
    }
}

@media (max-width: 820px) {
    .moduleWrapper .headerText,
    .moduleWrapper .projectText,
    .moduleWrapper .marketsText,
    .moduleWrapper .partnerText {
        font-size: 3vw;
        line-height: 3.2vw;
        padding: 2.57vw 3.704vw 5.25vw 3.704vw;
    }
    .moduleWrapper .header {
        height: unset;
        padding: 20.5vw 3.704vw 0;
    }
    .moduleWrapper .mapWrapper {
        padding: 2.63vw 3.704vw;
    }
    .moduleWrapper .projectWrapper,
    .moduleWrapper .marketsWrapper,
    .moduleWrapper .partnerWrapper {
        padding-top: 15.25vw;
    }
    .moduleWrapper .partnerWrapper {
        padding-bottom: 5vw;
    }
    .moduleWrapper .projectSlider {
        margin-left: 3.704vw;
    }
}

@media (max-width: 430px) {
    .moduleWrapper .headerText,
    .moduleWrapper .projectText,
    .moduleWrapper .marketsText,
    .moduleWrapper .partnerText {
        font-size: 4vw;
        line-height: 4.25vw;
        padding: 2.57vw 4.733vw 5.25vw 4.733vw;
    }
    .moduleWrapper .header {
        height: unset;
        padding: 35.5vw 4.733vw 0;
    }
    .moduleWrapper .mapWrapper {
        padding: 0 4.733vw;
    }
    .moduleWrapper .projectWrapper,
    .moduleWrapper .marketsWrapper,
    .moduleWrapper .partnerWrapper {
        padding-top: 15.25vw;
    }
    .moduleWrapper .partnerWrapper {
        padding-bottom: 5vw;
    }
    .moduleWrapper .projectSlider {
        margin-left: 4.733vw;
    }
}
