.moduleWrapper {
    padding: 5.78vw 7.35vw;
}

.moduleWrapper .headline {
    font-family: "Akzidenz Light";
    font-size: 2.36vw;
    line-height: 2.63vw;
    padding-bottom: 3.15vw;
}

.moduleWrapper .gridWrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr) [12];
    grid-template-columns: repeat(12, 1fr);
    -webkit-column-gap: 0.84vw;
    column-gap: 0.84vw;
}

.moduleWrapper .gridWrapper .img {
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
    grid-column: 1/6;
    aspect-ratio: 674/666;
    background-position: center;
    background-size: cover;
}

.moduleWrapper .gridWrapper .par {
    -ms-grid-column: 7;
    -ms-grid-column-span: 6;
    grid-column: 7/13;
    font-family: "Akzidenz Light";
    font-size: 1.29vw;
    line-height: 1.68vw;
}

@media (max-width: 820px) {
    .moduleWrapper {
        height: unset;
        padding: 7vw 4.032vw;
    }
    .moduleWrapper .headline {
        font-size: 3.06vw;
        line-height: 3.43vw;
        padding-bottom: 3.15vw;
    }
    .moduleWrapper .gridWrapper {
        -ms-grid-columns: (1fr) [12];
        grid-template-columns: repeat(12, 1fr);
    }
    .moduleWrapper .gridWrapper .par {
        -ms-grid-column: 6;
        -ms-grid-column-span: 7;
        grid-column: 6/13;
        font-size: 2.2vw;
        line-height: 2.68vw;
        padding-left: 2vw;
    }
}

@media (max-width: 430px) {
    .moduleWrapper {
        padding: 10vw 4.933vw;
    }
    .moduleWrapper .headline {
        font-size: 5vw;
        line-height: 5.5vw;
        padding-bottom: 7vw;
    }
    .moduleWrapper .gridWrapper {
        -ms-grid-columns: (1fr) [12];
        grid-template-columns: repeat(12, 1fr);
    }
    .moduleWrapper .gridWrapper .img {
        aspect-ratio: unset;
    }
    .moduleWrapper .gridWrapper .par {
        -ms-grid-column: 6;
        -ms-grid-column-span: 7;
        grid-column: 6/13;
        font-size: 4vw;
        line-height: 4.5vw;
        padding-left: 2vw;
    }
}
