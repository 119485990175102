.moduleWrapper {
    height: 80vh;
    width: 100%;
    padding-top: 1vw;
}

.moduleWrapper .image {
    width: 86.18vw;
    aspect-ratio: 1640/923;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: -webkit-grab;
    cursor: grab;
}

.moduleWrapper .image:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

@media (max-width: 820px) {
    .moduleWrapper {
        height: 40vh;
        padding-top: 1.829vw;
    }
}

@media (max-width: 430px) {
    .moduleWrapper {
        height: 30vh;
        padding-top: 4.933vw;
    }
}
