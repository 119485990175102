.moduleWrapper hr {
    color: #707070;
    margin-top: 0.68vw;
    opacity: 0.5;
}

.moduleWrapper .value {
    font-family: "Akzidenz";
    font-size: 3.68vw;
    line-height: 4.46vw;
}

.moduleWrapper .label {
    font-family: "Untitled";
    color: #fff;
    font-size: 1.05vw;
    opacity: 0.45;
}

@media (max-width: 1366px) {
    .moduleWrapper .value {
        font-size: 2.965vw;
    }
    .moduleWrapper .label {
        font-size: 0.89vw;
        text-transform: uppercase;
    }
}

@media (max-width: 820px) {
    .moduleWrapper .hr {
        margin-top: 1.605vw;
    }
    .moduleWrapper .value {
        font-size: 4.938vw;
        line-height: 7vw;
    }
    .moduleWrapper .label {
        font-size: 1.481vw;
        text-transform: uppercase;
    }
}

@media (max-width: 430px) {
    .moduleWrapper .hr {
        margin-top: 3.183vw;
    }
    .moduleWrapper .value {
        font-size: 10.61vw;
        line-height: 12vw;
    }
    .moduleWrapper .label {
        font-size: 3.183vw;
        text-transform: uppercase;
    }
}
