.moduleWrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr) [12];
    grid-template-columns: repeat(12, 1fr);
    -ms-grid-rows: (1fr) [3];
    grid-template-rows: repeat(3, 1fr);
    gap: 0.84vw;
    padding: 7.35vw;
}

.moduleWrapper .sqareImg1,
.moduleWrapper .sqareImg2,
.moduleWrapper .sqareImg3,
.moduleWrapper .sqareImg4 {
    aspect-ratio: 1/1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.moduleWrapper .sqareImg1,
.moduleWrapper .sqareImg3 {
    -ms-grid-column: 1;
    -ms-grid-column-span: 6;
    grid-column: 1/7;
}

.moduleWrapper .sqareImg2,
.moduleWrapper .sqareImg4 {
    -ms-grid-column: 7;
    -ms-grid-column-span: 6;
    grid-column: 7/13;
}

.moduleWrapper .horizontalImg {
    -ms-grid-column: 1;
    -ms-grid-column-span: 12;
    grid-column: 1 / span 12;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (max-width: 820px) {
    .moduleWrapper {
        padding: 7vw 4.032vw;
    }
}

@media (max-width: 430px) {
    .moduleWrapper {
        height: unset;
        padding: 7vw 4.933vw;
        gap: 1.829vw;
    }
}
