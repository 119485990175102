.moduleWrapper {
    cursor: pointer;
}

.moduleWrapper .imgWrapper {
    width: 100%;
    aspect-ratio: 397/540;
    margin-bottom: 1.47vw;
    overflow: hidden;
}

.moduleWrapper .imgWrapper .img {
    width: 100%;
    aspect-ratio: 397/540;
    background-size: cover;
    background-position: center;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}

.moduleWrapper .name {
    font-family: "Akzidenz";
    font-size: 1.84vw;
    line-height: 1.68vw;
    margin-bottom: 0.26vw;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.moduleWrapper .position {
    font-family: "Akzidenz";
    font-size: 0.84vw;
    line-height: 1.68vw;
    text-transform: uppercase;
    color: #706f6f;
}

.moduleWrapper:hover .img {
    scale: 1.05;
}

.moduleWrapper:hover .name {
    color: #706f6f;
}

.overlay {
    -webkit-transform: translateX(-150%);
    transform: translateX(-150%);
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    position: fixed;
    top: 5vh;
    left: 0;
    z-index: 99999;
    height: 90vh;
    width: 100%;
    background-color: #fff;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 35% 65%;
    grid-template-columns: 35% 65%;
    -webkit-box-shadow: 0px 0px 73px 100px rgba(0, 0, 0, 0.7);
    box-shadow: 0px 0px 73px 100px rgba(0, 0, 0, 0.7);
}

.overlay .imgWrapper {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}

.overlay .bioWrapper {
    overflow-y: scroll;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2/3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 2.63vw;
}

.overlay .bioWrapper .close {
    width: 1vw;
    aspect-ratio: 1/1;
    background-image: url(../../assets/images/svg/close.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    -ms-flex-item-align: end;
    align-self: flex-end;
    cursor: pointer;
}

.overlay .bioWrapper .descWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 1.57vw;
    justify-self: center;
    padding: 18vh 5vw 0;
}

.overlay .bioWrapper .descWrapper .name,
.overlay .bioWrapper .descWrapper .position {
    font-family: "Akzidenz";
}

.overlay .bioWrapper .descWrapper .name {
    font-size: 1.84vw;
}

.overlay .bioWrapper .descWrapper .position {
    font-size: 1vw;
    line-height: 2.1vw;
    color: #706f6f;
    text-transform: uppercase;
}

.overlay .bioWrapper .descWrapper .bio {
    font-family: "Untitled";
    font-size: 1.24vw;
    line-height: 1.68vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 1.57vw;
}

.show {
    -webkit-transform: translateX(0) !important;
    transform: translateX(0) !important;
}

@media (max-width: 820px) {
    .moduleWrapper .name {
        font-size: 3.704vw;
        line-height: 3vw;
        letter-spacing: -0.123vw;
        margin-bottom: 1.2vw;
    }
    .moduleWrapper .position {
        font-size: 1.6vw;
    }
    .overlay {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 80%;
        left: 10%;
    }
    .overlay .imgWrapper {
        height: 40vh;
        width: 100%;
    }
    .overlay .bioWrapper .close {
        width: 3vw;
    }
    .overlay .bioWrapper .descWrapper {
        padding: 3vw 5vw 0;
        row-gap: 3vw;
    }
    .overlay .bioWrapper .descWrapper .name {
        font-size: 3.086vw;
        line-height: 3.5vw;
    }
    .overlay .bioWrapper .descWrapper .position {
        font-size: 1.852vw;
        line-height: 3vw;
    }
    .overlay .bioWrapper .descWrapper .bio {
        font-size: 2.222vw;
        line-height: 2.469vw;
    }
}

@media (max-width: 430px) {
    .moduleWrapper .name {
        font-size: 6.039vw;
        line-height: 6vw;
        letter-spacing: -0.123vw;
        margin-bottom: 2.2vw;
    }
    .moduleWrapper .position {
        font-size: 2.899vw;
    }
    .overlay {
        -webkit-transform: translateX(-190%);
        transform: translateX(-190%);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 80%;
        left: 10%;
        overflow: scroll;
    }
    .overlay .imgWrapper {
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        height: 40vh;
        width: 100%;
    }
    .overlay .bioWrapper .close {
        width: 3vw;
    }
    .overlay .bioWrapper .descWrapper {
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        padding: 3vw 5vw 0;
        row-gap: 3vw;
    }
    .overlay .bioWrapper .descWrapper .name {
        font-size: 5.333vw;
        line-height: 6vw;
    }
    .overlay .bioWrapper .descWrapper .position {
        font-size: 3vw;
        line-height: 4vw;
    }
    .overlay .bioWrapper .descWrapper .bio {
        font-size: 3.2vw;
        line-height: 3.7vw;
        row-gap: 5vw;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
    }
}
