.moduleWrapper {
  height: 100vh;
  max-width: 100%;
  overflow: hidden;
}

.moduleWrapper .img {
  aspect-ratio: 16/9;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
}

@media (max-width: 820px) {
  .moduleWrapper {
    height: unset;
  }
}
