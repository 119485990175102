.overlay {
    position: absolute;
    z-index: 999;
    padding: 1.57vw;
    width: 95%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.overlay .logo {
    font-family: "Akzidenz Light";
    font-size: 1.32vw;
}

.overlay .logo::before {
    content: "";
    width: 8.15vw;
    aspect-ratio: 212/48;
    background-image: url(../../assets/images/svg/menuLogo.svg);
    background-repeat: no-repeat;
    background-size: contain;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 0.5vw;
}

.buttons .menuButton {
    font-family: "Akzidenz";
    font-size: 0.78vw;
    height: 3.1vw;
    line-height: 3.1vw;
    vertical-align: middle;
    cursor: pointer;
    position: fixed;
    right: 0;
    color: #2e2e2e;
    margin-right: 6.3vw;
}

.buttons .contactButton {
    height: 3vw;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: #2e2e2e;
    background-image: url(../../assets/images/svg/contactIco.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    right: 1.57vw;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.buttons .contactButton:hover {
    height: 3.5vw;
    -webkit-transform: translate(6.25%, -6.25%);
    transform: translate(6.25%, -6.25%);
}

.menu {
    position: fixed;
    top: 0;
    height: 100lvh;
    width: 100%;
    z-index: 1000;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    background-color: black;
    color: #fff;
}

.menu .topBar {
    margin: 1.57vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 0.79vw;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.menu .topBar .whLogo::before {
    content: "";
    width: 8.15vw;
    aspect-ratio: 212/48;
    background-image: url(../../assets/images/svg/livwrk_wh.svg);
    background-repeat: no-repeat;
    background-size: contain;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.menu .menuContent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 1.57vw;
    height: 85svh;
}

.menu .menuContent .addresses {
    -ms-flex-preferred-size: 35%;
    flex-basis: 35%;
    -ms-flex-item-align: end;
    align-self: flex-end;
    font-family: "Untitled";
    font-size: 0.79vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 4.2vw;
}

.menu .menuContent .addresses .addressWrapper {
    width: 22%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 1.57vw;
}

.menu .menuContent .menuButtons {
    font-family: "Akzidenz";
    font-size: 4.73vw;
    line-height: 5.25vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 50%;
    padding-top: 3.68vw;
}

.active {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

@media (max-width: 1366px) {
    .overlay {
        padding: 2.224vw;
    }
    .overlay .logo {
        font-size: 2.224vw;
    }
    .overlay .logo::before {
        width: 13.64vw;
        margin-right: 0.6vw;
    }
    .buttons .menuButton {
        font-size: 1.112vw;
        margin-right: 7.858vw;
    }
    .buttons .contactButton {
        right: 2.224vw;
    }
    .menu .topBar .whLogo::before {
        width: 10vw;
    }
    .menu .menuContent .addresses {
        font-size: 0.89vw;
    }
    .menu .menuContent .addresses .addressWrapper {
        width: 26%;
    }
    .menu .menuContent .menuButtons {
        padding-top: 6vw;
    }
}

@media (max-width: 820px) {
    .overlay {
        padding: 4.032vw;
    }
    .overlay .logo {
        font-size: 3.36vw;
    }
    .overlay .logo::before {
        width: 20.833vw;
        margin-right: 0.6vw;
    }
    .buttons .menuButton {
        font-size: 2.016vw;
        margin-right: 13.441vw;
        top: 5.7vw;
    }
    .buttons .contactButton {
        height: 6.5vw;
        right: 4.032vw;
    }
    .buttons .contactButton:hover {
        height: 8vw;
        -webkit-transform: translate(10%, -10%);
        transform: translate(10%, -10%);
    }
    .menu .topBar {
        margin: 2.57vw;
    }
    .menu .topBar .whLogo {
        margin-left: 0.3vw;
    }
    .menu .topBar .whLogo::before {
        width: 20vw;
    }
    .menu .menuContent {
        height: 90svh;
        padding: 2.57vw;
    }
    .menu .menuContent .addresses {
        -ms-flex-preferred-size: 40%;
        flex-basis: 40%;
        font-size: 2.016vw;
    }
    .menu .menuContent .addresses .addressWrapper {
        width: 70%;
    }
    .menu .menuContent .menuButtons {
        width: 100%;
        font-size: 7vw;
        line-height: 8vw;
        padding-top: 20vh;
    }
}

@media (max-width: 430px) {
    .overlay {
        padding: 4.933vw;
    }
    .overlay .logo {
        font-size: 5vw;
        width: 50vw;
    }
    .overlay .logo::before {
        width: 43.467vw;
        margin-right: 0.6vw;
    }
    .buttons .menuButton {
        font-size: 3.2vw;
        margin-right: 20.533vw;
        top: 12.533vw;
    }
    .buttons .contactButton {
        height: 12vw;
        right: 5vw;
        top: 8vw;
    }
    .buttons .contactButton:hover {
        height: 14vw;
        -webkit-transform: translate(7.1%, -7.1%);
        transform: translate(7.1%, -7.1%);
    }
    .menu .topBar {
        margin: 2.57vw;
    }
    .menu .topBar .whLogo {
        margin-left: 0.5vw;
        padding-top: 5vw;
    }
    .menu .topBar .whLogo::before {
        width: 38.133vw;
    }
    .menu .menuContent {
        height: 90svh;
        padding: 3vw;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        position: relative;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }
    .menu .menuContent .addresses {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        -ms-flex-preferred-size: unset;
        flex-basis: unset;
        font-size: 3.2vw;
        position: unset;
        -ms-flex-item-align: start;
        align-self: flex-start;
        padding-bottom: 4svh;
    }
    .menu .menuContent .addresses .addressWrapper {
        width: 70%;
    }
    .menu .menuContent .menuButtons {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        width: 100%;
        font-size: 10vw;
        line-height: 13vw;
        padding-bottom: 12svh;
        -ms-flex-item-align: end;
        align-self: flex-end;
        padding-top: 0;
    }
}
