.moduleWrapper {
    width: 100%;
    aspect-ratio: 400/530;
    background-color: #fff;
    padding: 3.68vw 1.84vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 5.25vw;
    cursor: pointer;
}

.moduleWrapper .title {
    font-family: "Akzidenz Medium";
    font-size: 1.31vw;
}

.moduleWrapper .infoWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.moduleWrapper .infoWrapper .icon {
    height: 3.15vw;
    width: 100%;
    background-position: left;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 1.57vw;
}

.moduleWrapper .infoWrapper .descTitle {
    font-family: "Akzidenz";
    font-size: 1.2vw;
    line-height: 1.47vw;
    padding-bottom: 0.52vw;
}

.moduleWrapper .infoWrapper .desc {
    font-family: "Akzidenz Light";
    font-size: 1.2vw;
    line-height: 1.47vw;
}

@media (max-width: 820px) {
    .moduleWrapper .title {
        font-size: 2.249vw;
    }
    .moduleWrapper .infoWrapper .icon {
        height: 5vw;
    }
    .moduleWrapper .infoWrapper .descTitle {
        padding-bottom: 1vw;
    }
    .moduleWrapper .infoWrapper .descTitle,
    .moduleWrapper .infoWrapper .desc {
        font-size: 2vw;
        line-height: 2.4vw;
    }
}

@media (max-width: 430px) {
    .moduleWrapper .title {
        font-size: 3.2vw;
    }
    .moduleWrapper .infoWrapper .icon {
        height: 7vw;
    }
    .moduleWrapper .infoWrapper .descTitle {
        padding-bottom: 2vw;
    }
    .moduleWrapper .infoWrapper .descTitle,
    .moduleWrapper .infoWrapper .desc {
        font-size: 3.2vw;
        line-height: 3.4vw;
    }
}
