.moduleWrapper {
    min-height: 100vh;
    padding-top: 16.26vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #eeede3;
}

.moduleWrapper .gridWrapper {
    position: relative;
    padding: 0 7.35vw;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr) [12];
    grid-template-columns: repeat(12, 1fr);
    gap: 0.79vw;
    padding-top: 2.36vw;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    overflow: hidden;
}

.moduleWrapper .gridWrapper .left {
    font-family: "Akzidenz";
    font-size: 1.31vw;
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
    grid-column: 1/6;
    position: relative;
}

.moduleWrapper .gridWrapper .left .firstBlock {
    max-width: 80%;
}

.moduleWrapper .gridWrapper .left .secondBlock {
    padding-right: 3.41vw;
    padding-top: 3.15vw;
}

.moduleWrapper .gridWrapper .left .thirdBlock {
    padding-top: 1.57vw;
}

.moduleWrapper .gridWrapper .left .button {
    margin-top: 2.63vw;
}

.moduleWrapper .gridWrapper .bottomWrapper {
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
    grid-column: 1/6;
    font-family: "Untitled";
    font-size: 0.79vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 1.57vw;
    padding: 10vw 0 3vw;
}

.moduleWrapper .gridWrapper .bottomWrapper .policiesWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-column-gap: 2.63vw;
    column-gap: 2.63vw;
}

.moduleWrapper .gridWrapper .addressesWrapper {
    -ms-grid-column: 6;
    -ms-grid-column-span: 2;
    grid-column: 6/8;
    -ms-grid-row: 1;
    grid-row-start: 1;
    font-family: "Untitled";
    font-size: 0.79vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 1.57vw;
}

.moduleWrapper .gridWrapper .addressesWrapper .firstAddressWrapper,
.moduleWrapper .gridWrapper .addressesWrapper .secondAddressWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 0.52vw;
}

.moduleWrapper .gridWrapper .addressesWrapper .firstAddressWrapper {
    width: 55%;
}

.moduleWrapper .gridWrapper .addressesWrapper .secondAddressWrapper {
    width: 55%;
}

.moduleWrapper .gridWrapper .rightWrapper {
    -ms-grid-column: 8;
    -ms-grid-column-span: 5;
    grid-column: 8/13;
    font-family: "Untitled";
    -ms-grid-row: 1;
    grid-row-start: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 1.57vw;
    width: 95%;
}

.moduleWrapper .gridWrapper .rightWrapper .rightBlock {
    font-size: 1.31vw;
}

.moduleWrapper .gridWrapper .rightWrapper .socialWrapper {
    font-size: 0.79vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 0.26vw;
}

.linkUnderline {
    display: inline-block;
    vertical-align: bottom;
    position: relative;
    padding: 0.1vw 0;
    overflow: hidden;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.linkUnderline::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.05vw;
    background-color: #707070;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.linkUnderline:hover::after {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}

@media (max-width: 1366px) {
    .moduleWrapper {
        padding-top: 6.449vw;
    }
    .moduleWrapper .gridWrapper {
        padding: 4.077vw 10.378vw 0;
    }
    .moduleWrapper .gridWrapper .left {
        font-size: 1.334vw;
    }
    .moduleWrapper .gridWrapper .left .firstBlock {
        max-width: unset;
    }
    .moduleWrapper .gridWrapper .left .secondBlock {
        padding-right: unset;
    }
    .moduleWrapper .gridWrapper .left .button {
        margin-top: 4.596vw;
    }
    .moduleWrapper .gridWrapper .bottomWrapper {
        font-size: 0.89vw;
        row-gap: 0.371vw;
        bottom: 3.558vw;
    }
    .moduleWrapper .gridWrapper .bottomWrapper .policiesWrapper {
        -webkit-column-gap: 4vw;
        column-gap: 4vw;
    }
    .moduleWrapper .gridWrapper .addressesWrapper {
        -ms-grid-column: 7;
        -ms-grid-column-span: 2;
        grid-column: 7/9;
        font-size: 0.89vw;
    }
    .moduleWrapper .gridWrapper .addressesWrapper .firstAddressWrapper,
    .moduleWrapper .gridWrapper .addressesWrapper .secondAddressWrapper {
        width: 66%;
    }
    .moduleWrapper .gridWrapper .rightWrapper {
        -ms-grid-column: 10;
        -ms-grid-column-span: 3;
        grid-column: 10/13;
    }
    .moduleWrapper .gridWrapper .rightWrapper .rightBlock {
        font-size: 1.334vw;
    }
    .moduleWrapper .gridWrapper .rightWrapper .socialWrapper {
        font-size: 0.89vw;
    }
}

@media (max-width: 820px) {
    .moduleWrapper {
        min-height: -webkit-max-content;
        min-height: -moz-max-content;
        min-height: max-content;
    }
    .moduleWrapper .gridWrapper {
        padding: 0 3.704vw;
        -ms-grid-columns: (1fr) [8];
        grid-template-columns: repeat(8, 1fr);
        gap: 1.975vw;
        padding-top: 6.79vw;
    }
    .moduleWrapper .gridWrapper .left {
        font-size: 1.852vw;
        -ms-grid-column: 1;
        -ms-grid-column-span: 4;
        grid-column: 1/5;
    }
    .moduleWrapper .gridWrapper .left .secondBlock {
        width: 80%;
        padding-top: 9.136vw;
    }
    .moduleWrapper .gridWrapper .left .thirdBlock {
        padding-top: 3vw;
        width: 80%;
    }
    .moduleWrapper .gridWrapper .left .button {
        margin-top: 6.173vw;
    }
    .moduleWrapper .gridWrapper .bottomWrapper {
        -ms-grid-column: 1;
        -ms-grid-column-span: 8;
        grid-column: 1/9;
        width: 100%;
        font-size: 1.481vw;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-column-gap: 15vw;
        column-gap: 15vw;
        bottom: 4.321vw;
        position: relative;
    }
    .moduleWrapper .gridWrapper .bottomWrapper .policiesWrapper {
        -webkit-column-gap: 5vw;
        column-gap: 5vw;
    }
    .moduleWrapper .gridWrapper .bottomWrapper .link247 {
        position: absolute;
        right: 0;
    }
    .moduleWrapper .gridWrapper .addressesWrapper {
        -ms-grid-column: 5;
        -ms-grid-column-span: 4;
        grid-column: 5/9;
        font-size: 1.481vw;
        padding-top: 60%;
        width: 47%;
    }
    .moduleWrapper .gridWrapper .rightWrapper {
        -ms-grid-column: 5;
        -ms-grid-column-span: 4;
        grid-column: 5/9;
    }
    .moduleWrapper .gridWrapper .rightWrapper .rightBlock {
        font-size: 1.852vw;
    }
    .moduleWrapper .gridWrapper .rightWrapper .socialWrapper {
        padding-top: 5.5vw;
        font-size: 1.481vw;
        row-gap: 1.235vw;
    }
}

@media (max-width: 430px) {
    .moduleWrapper {
        min-height: 120lvh;
    }
    .moduleWrapper .gridWrapper {
        padding: 0 5.333vw;
        -ms-grid-columns: (1fr) [4];
        grid-template-columns: repeat(4, 1fr);
        gap: 0;
        padding-top: 6.79vw;
    }
    .moduleWrapper .gridWrapper .left {
        -ms-grid-row: 1;
        grid-row-start: 1;
        font-size: 4vw;
        -ms-grid-column: 1;
        -ms-grid-column-span: 4;
        grid-column: 1/5;
    }
    .moduleWrapper .gridWrapper .left .mobileRightBlock {
        padding-top: 6.4vw;
        width: 95%;
    }
    .moduleWrapper .gridWrapper .left .secondBlock {
        width: 80%;
        padding-top: 9.136vw;
    }
    .moduleWrapper .gridWrapper .left .thirdBlock {
        padding-top: 5vw;
        width: 80%;
    }
    .moduleWrapper .gridWrapper .left .button {
        margin-top: 10vw;
    }
    .moduleWrapper .gridWrapper .bottomWrapper {
        -ms-grid-column: 1;
        -ms-grid-column-span: 4;
        grid-column: 1/5;
        width: 100%;
        height: -webkit-max-content;
        height: -moz-max-content;
        height: max-content;
        font-size: 3.2vw;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-column-gap: 10vw;
        column-gap: 10vw;
    }
    .moduleWrapper .gridWrapper .bottomWrapper .policiesWrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        row-gap: 3vw;
    }
    .moduleWrapper .gridWrapper .bottomWrapper .link247 {
        position: unset;
        -ms-flex-item-align: end;
        -ms-grid-row-align: end;
        align-self: end;
        width: 25vw;
        height: -webkit-max-content;
        height: -moz-max-content;
        height: max-content;
    }
    .moduleWrapper .gridWrapper .addressesWrapper {
        -ms-grid-row: 2;
        grid-row-start: 2;
        -ms-grid-column: 3;
        -ms-grid-column-span: 2;
        grid-column: 3/5;
        font-size: 3.2vw;
        padding-top: 0;
        width: 100%;
    }
    .moduleWrapper .gridWrapper .rightWrapper {
        -ms-grid-row: 2;
        grid-row-start: 2;
        -ms-grid-column: 1;
        -ms-grid-column-span: 2;
        grid-column: 1/3;
    }
    .moduleWrapper .gridWrapper .rightWrapper .rightBlock {
        display: none;
    }
    .moduleWrapper .gridWrapper .rightWrapper .socialWrapper {
        padding-top: 0;
        font-size: 3.2vw;
        row-gap: 1.235vw;
    }
}
