.moduleWrapper {
    min-height: 100vh;
    background-color: #eeede3;
}

.moduleWrapper .header {
    height: 77vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.moduleWrapper .header .headerOverlay {
    height: 100%;
    width: 100%;
    background-color: #fff;
    opacity: 0.36;
}

.moduleWrapper .header .titleWrapper {
    position: relative;
    top: -50%;
    padding-left: 7.35vw;
}

.moduleWrapper .header .titleWrapper h1 {
    font-family: "Akzidenz Light";
    font-size: 3.68vw;
    line-height: 3.68vw;
    width: 52.54vw;
}

.moduleWrapper .header .titleWrapper .filters {
    padding-top: 2.63vw;
}

.moduleWrapper .header .titleWrapper .filters ul {
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-column-gap: 0.1vw;
    column-gap: 0.1vw;
}

.moduleWrapper .header .titleWrapper .filters li {
    font-family: "Untitled";
    font-size: 0.68vw;
    cursor: pointer;
    text-transform: uppercase;
    padding: 0.52vw 1.31vw;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border: 1px solid transparent;
}

.moduleWrapper .projectsGrid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr) [3];
    grid-template-columns: repeat(3, 1fr);
    -webkit-column-gap: 0.79vw;
    column-gap: 0.79vw;
    row-gap: 5.25vw;
    padding: 7.35vw;
}

.moduleWrapper .active {
    border: 1px solid #000 !important;
    border-radius: 16px;
    padding: 0.52vw 1.31vw;
}

@media (max-width: 820px) {
    .moduleWrapper .header .titleWrapper {
        top: -60%;
    }
    .moduleWrapper .header .titleWrapper h1 {
        font-size: 8.642vw;
        line-height: 8.642vw;
        width: 85%;
    }
    .moduleWrapper .header .titleWrapper .filters {
        padding-top: 6.173vw;
    }
    .moduleWrapper .header .titleWrapper .filters ul {
        -webkit-column-gap: 0.5vw;
        column-gap: 0.5vw;
    }
    .moduleWrapper .header .titleWrapper .filters li {
        font-size: 1.605vw;
        padding: 1.235vw 3.086vw;
    }
    .moduleWrapper .projectsGrid {
        -ms-grid-columns: (1fr) [2];
        grid-template-columns: repeat(2, 1fr);
        -webkit-column-gap: 1.852vw;
        column-gap: 1.852vw;
    }
}

@media (max-width: 430px) {
    .moduleWrapper .header .titleWrapper {
        top: -60%;
    }
    .moduleWrapper .header .titleWrapper h1 {
        font-size: 8.642vw;
        line-height: 8.642vw;
        width: 85%;
    }
    .moduleWrapper .header .titleWrapper .filters {
        padding-top: 13.333vw;
    }
    .moduleWrapper .header .titleWrapper .filters ul {
        -webkit-column-gap: 0.5vw;
        column-gap: 0.5vw;
    }
    .moduleWrapper .header .titleWrapper .filters li {
        font-size: 3vw;
        padding: 1.235vw 3.086vw;
    }
    .moduleWrapper .projectsGrid {
        -ms-grid-columns: (1fr) [1];
        grid-template-columns: repeat(1, 1fr);
        -webkit-column-gap: 0;
        column-gap: 0;
    }
}
