.moduleWrapper {
    min-height: 100vh;
}

.moduleWrapper .header .imageWrapper {
    width: 100%;
    aspect-ratio: 16/9;
}

.moduleWrapper .header .imageWrapper .img {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.moduleWrapper .header .detailsWrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr) [2];
    grid-template-columns: repeat(2, 1fr);
    padding: 2.63vw 7.35vw 7.88vw;
}

.moduleWrapper .header .detailsWrapper .projectName,
.moduleWrapper .header .detailsWrapper .headline,
.moduleWrapper .header .detailsWrapper .role {
    font-family: "Akzidenz";
    font-size: 2.36vw;
}

.moduleWrapper .header .detailsWrapper .role {
    color: #525252;
    width: 75%;
}

@media (max-width: 820px) {
    .moduleWrapper .header .detailsWrapper {
        padding: 7vw 4.032vw;
    }
    .moduleWrapper .header .detailsWrapper .projectName,
    .moduleWrapper .header .detailsWrapper .headline,
    .moduleWrapper .header .detailsWrapper .role {
        font-size: 2.8vw;
    }
    .moduleWrapper .header .detailsWrapper .role {
        width: 80%;
    }
}

@media (max-width: 430px) {
    .moduleWrapper .header .imageWrapper {
        aspect-ratio: 5/6;
    }
    .moduleWrapper .header .detailsWrapper {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        padding: 10vw 4.933vw;
        row-gap: 10vw;
    }
    .moduleWrapper .header .detailsWrapper .projectName {
        font-size: 7vw;
    }
    .moduleWrapper .header .detailsWrapper .headline,
    .moduleWrapper .header .detailsWrapper .role {
        font-size: 5vw;
    }
    .moduleWrapper .header .detailsWrapper .role {
        color: #525252;
        width: 75%;
    }
}
