.moduleWrapper {
    padding-left: 7.35vw;
}

.moduleWrapper h2 {
    font-family: "Akzidenz";
    font-size: 2.63vw;
    line-height: 4.2vw;
}

.moduleWrapper hr {
    color: #707070;
    opacity: 0.27;
    margin-top: 0.68vw;
}

@media (max-width: 1366px) {
    .moduleWrapper {
        padding-left: 10.37vw;
    }
}

@media (max-width: 820px) {
    .moduleWrapper {
        padding-left: 3.704vw;
    }
    .moduleWrapper h2 {
        font-size: 4.938vw;
    }
    .moduleWrapper hr {
        margin-top: 1.358vw;
    }
}

@media (max-width: 430px) {
    .moduleWrapper {
        padding-left: 4.733vw;
    }
    .moduleWrapper h2 {
        font-size: 6.667vw;
    }
    .moduleWrapper hr {
        margin-top: 3.659vw;
    }
}
