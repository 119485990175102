.button {
    font-family: "Untitled";
    font-size: 0.68vw;
    border: 1px solid #707070;
    border-radius: 16px;
    padding: 0.89vw 2.63vw;
    display: inline-block;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.button:hover {
    background-color: #707070;
    color: #fff;
}

@media (max-width: 1366px) {
    .button {
        font-size: 0.89vw;
    }
}

@media (max-width: 820px) {
    .button {
        font-size: 1.605vw;
        padding: 1.975vw 6.173vw;
    }
}

@media (max-width: 430px) {
    .button {
        font-size: 2.899vw;
        padding: 2.865vw 6.522vw;
    }
}
