@font-face {
    font-family: "Akzidenz";
    src: url(./assets/fonts/Akzidenz\ Grotesk\ Pro\ Regular\ Regular.otf) format("opentype");
}
@font-face {
    font-family: "Akzidenz Light";
    src: url(./assets/fonts/Akzidenz\ Grotesk\ Pro\ Light\ Regular.otf) format("opentype");
}
@font-face {
    font-family: "Akzidenz Light Italic";
    src: url(./assets/fonts/Akzidenz\ Grotesk\ Pro\ Light\ Italic.otf) format("opentype");
}
@font-face {
    font-family: "Akzidenz Medium";
    src: url(./assets/fonts/Akzidenz\ Grotesk\ Pro\ Med\ Regular.otf) format("opentype");
}
@font-face {
    font-family: "Untitled";
    src: url(./assets/fonts/UntitledSans-Regular.otf) format("opentype");
}

html {
    font-size: 62.5%;
    height: -webkit-fill-available;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    min-height: 100vh;
    min-height: -webkit-fill-available;
}
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}

iframe {
    border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: normal;
    font-style: normal;
}

.grayOnHover {
    transition: opacity 0.2s ease-in-out;
}
.grayOnHover:hover {
    opacity: 0.5;
}
