.moduleWrapper {
    background-color: #000;
    color: #fff;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr) [12];
    grid-template-columns: repeat(12, 1fr);
    -webkit-column-gap: 0.84vw;
    column-gap: 0.84vw;
    padding: 0 7.35vw;
    min-height: 100vh;
}

.moduleWrapper .leftColumn {
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
    grid-column: 1/6;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    row-gap: 7vw;
    padding: 7.5vw 0;
}

.moduleWrapper .leftColumn .desc {
    font-size: 2.36vw;
    line-height: 2.63vw;
    font-family: "Akzidenz Light";
}

.moduleWrapper .leftColumn .learnMore {
    font-family: "Untitled";
    font-size: 0.89vw;
}

.moduleWrapper .rightColumn {
    -ms-grid-column: 8;
    -ms-grid-column-span: 5;
    grid-column: 8/13;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 6.83vw 0;
}

@media (max-width: 1366px) {
    .moduleWrapper {
        padding: 0 3vw 0 10.37vw;
    }
    .moduleWrapper .logo img {
        width: 4.67vw;
    }
    .moduleWrapper .leftColumn .desc {
        font-size: 2.595vw;
        line-height: 2.595vw;
    }
    .moduleWrapper .leftColumn .learnMore {
        font-size: 1.186vw;
    }
}

@media (max-width: 820px) {
    .moduleWrapper {
        padding: 7.407vw 3.704vw;
    }
    .moduleWrapper .logo img {
        width: 7.037vw;
    }
    .moduleWrapper .leftColumn {
        -ms-grid-column: 1;
        -ms-grid-column-span: 12;
        grid-column: 1/13;
        padding: 0;
    }
    .moduleWrapper .leftColumn .desc {
        font-size: 4.321vw;
        line-height: 4.321vw;
        width: 90%;
    }
    .moduleWrapper .leftColumn .learnMore {
        font-size: 1.975vw;
    }
    .moduleWrapper .rightColumn {
        -ms-grid-column: 1;
        -ms-grid-column-span: 12;
        grid-column: 1/13;
    }
}

@media (max-width: 430px) {
    .moduleWrapper {
        padding: 15.915vw 5.305vw;
        min-height: 120vh;
    }
    .moduleWrapper .logo img {
        width: 15.119vw;
    }
    .moduleWrapper .leftColumn {
        -ms-grid-column: 1;
        -ms-grid-column-span: 12;
        grid-column: 1/13;
        padding: 0 0 5vw 0;
    }
    .moduleWrapper .leftColumn .desc {
        font-size: 6.631vw;
        line-height: 7.427vw;
        width: 100%;
    }
    .moduleWrapper .leftColumn .learnMore {
        font-size: 4.244vw;
    }
    .moduleWrapper .rightColumn {
        -ms-grid-column: 1;
        -ms-grid-column-span: 12;
        grid-column: 1/13;
    }
}
