.moduleWrapper {
    cursor: pointer;
    aspect-ratio: 5/6;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.moduleWrapper:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.moduleWrapper .imgWrapper {
    width: 100%;
    aspect-ratio: 397/286;
    overflow: hidden;
    margin-bottom: 1.47vw;
}

.moduleWrapper .imgWrapper .img {
    aspect-ratio: 397/286;
    width: 100%;
    background-size: cover;
    background-position: center;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.moduleWrapper .title {
    font-family: "Akzidenz";
    font-size: 1.31vw;
    line-height: 1.57vw;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.moduleWrapper:hover .img {
    scale: 1.05;
}

.moduleWrapper:hover .title {
    color: #706f6f;
}

.moduleWrapper .btn {
    position: absolute;
    bottom: 0;
}

.overlay {
    -webkit-transform: translateX(-150%);
    transform: translateX(-150%);
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    position: fixed;
    top: 5vh;
    left: 0;
    z-index: 99999;
    height: 90vh;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 35% 65%;
    grid-template-columns: 35% 65%;
    -webkit-box-shadow: 0px 0px 73px 100px rgba(0, 0, 0, 0.7);
    box-shadow: 0px 0px 73px 100px rgba(0, 0, 0, 0.7);
}

.overlay .imgWrapper {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.overlay .contentWrapper {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2/3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 2.63vw;
}

.overlay .contentWrapper .close {
    width: 1vw;
    aspect-ratio: 1/1;
    background-image: url(../../assets/images/svg/close.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    -ms-flex-item-align: end;
    align-self: flex-end;
    cursor: pointer;
}

.overlay .contentWrapper .descWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 1.57vw;
    justify-self: center;
    padding: 18vh 5vw 0;
}

.overlay .contentWrapper .descWrapper .title,
.overlay .contentWrapper .descWrapper .date {
    font-family: "Akzidenz";
}

.overlay .contentWrapper .descWrapper .title {
    font-size: 1.84vw;
}

.overlay .contentWrapper .descWrapper .date {
    font-size: 1vw;
    line-height: 2.1vw;
    color: #706f6f;
    text-transform: uppercase;
}

.overlay .contentWrapper .descWrapper .content {
    font-family: "Untitled";
    font-size: 1.05vw;
    line-height: 1.68vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 1.57vw;
}

.show {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

@media (max-width: 1366px) {
    .moduleWrapper {
        aspect-ratio: 20/23;
    }
    .moduleWrapper .imgWrapper {
        aspect-ratio: 260/187;
    }
    .moduleWrapper .imgWrapper .img {
        aspect-ratio: 260/187;
    }
    .moduleWrapper .title {
        font-size: 1.334vw;
    }
}

@media (max-width: 820px) {
    .moduleWrapper {
        aspect-ratio: 10/13;
    }
    .moduleWrapper .title {
        font-size: 2.222vw;
        line-height: 2.469vw;
    }
    .overlay {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .overlay .imgWrapper {
        height: 40vh;
        width: 100%;
    }
    .overlay .contentWrapper .close {
        width: 3vw;
    }
    .overlay .contentWrapper .descWrapper {
        padding: 5vw 5vw 0;
        row-gap: 3vw;
    }
    .overlay .contentWrapper .descWrapper .title {
        font-size: 3.086vw;
        line-height: 3.5vw;
    }
    .overlay .contentWrapper .descWrapper .date {
        font-size: 1.852vw;
        line-height: 3vw;
    }
    .overlay .contentWrapper .descWrapper .content {
        font-size: 2.222vw;
        line-height: 2.469vw;
    }
}

@media (max-width: 430px) {
    .moduleWrapper {
        aspect-ratio: 30/39;
    }
    .moduleWrapper .title {
        font-size: 3.623vw;
        line-height: 4.348vw;
    }
    .overlay .imgWrapper {
        height: 30vh;
        width: 100%;
    }
    .overlay .contentWrapper {
        padding: 4vw;
    }
    .overlay .contentWrapper .close {
        width: 4vw;
    }
    .overlay .contentWrapper .descWrapper {
        padding: 5vw 3vw 0;
        row-gap: 5vw;
    }
    .overlay .contentWrapper .descWrapper .title {
        font-size: 5.333vw;
        line-height: 6vw;
    }
    .overlay .contentWrapper .descWrapper .date {
        font-size: 3vw;
        line-height: 4vw;
    }
    .overlay .contentWrapper .descWrapper .content {
        font-size: 3.2vw;
        line-height: 3.7vw;
        row-gap: 5vw;
    }
}
